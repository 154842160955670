<template>
  <b-modal id="edit-password-modal" hide-footer centered hide-header>
    <b-row class="pb-2">
      <b-col cols="8">
        <span>{{ $t("g.editPassword") }}</span>
      </b-col>
      <b-col cols="4" align="end" @click="onCancel">
        <span style="cursor: pointer">
          <img
            :src="require('@/assets/images/pages/personal/icons/Icon.png')"
          />
        </span>
      </b-col>
    </b-row>
    <validation-observer ref="changePasswordForm">
      <b-form @submit.prevent="validation">
        <b-row align-h="end">
          <b-col cols="12">
            <b-form-group>
              <validation-provider
                rules="required"
                name="current password"
                #default="{ errors }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model="passwordData.current"
                    :placeholder="$t('g.currentPassword')"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">
                  {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider
                rules="required"
                name="New password"
                #default="{ errors }"
                vid="pass"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    :type="newPasswordFieldType"
                    v-model="passwordData.new_password"
                    :placeholder="$t('g.newPassword')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="newPasswordToggleIcon"
                      @click="toggleNewPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider
                rules="required|confirmed:pass"
                name="confirm password"
                #default="{ errors }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    :type="confirmPasswordFieldType"
                    v-model="passwordData.confirem_password"
                    :placeholder="$t('g.newPasswordAgian')"
                    :state="errors.length > 0 ? false : null"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="confirmPasswordToggleIcon"
                      @click="toggleConfirmPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="mt-1 mb-1" cols="auto">
            <b-button
              @click="onCancel()"
              variant="outline-transparent"
            >
              {{ $t("g.cancel") }}
            </b-button>
            <b-button type="submit" variant="primary">
              {{ $t("g.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <overlay-component :isLoading="overlayLoading" />
  </b-modal>
</template>

<script>
import { required, password, confirmed } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BRow,
  BButton,
  BOverlay,
  BCol,
  BForm,
  BModal,
  BProgress,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormFile,
  BCardText,
  BInputGroupAppend,
} from "bootstrap-vue";
export default {
  name: "overlayForm",
  components: {
    OverlayComponent,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BForm,
    BProgress,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormFile,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BModal,
    BInputGroupAppend,
  },
  data() {
    return {
      overlayLoading: false,
      passwordData: {
        current: null,
        new_password: null,
        confirem_password: null,
      },
      passwordFieldType: "password",
      newPasswordFieldType: "password",
      confirmPasswordFieldType: "password",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordVisibility() {
      if (this.passwordFieldType === "password") {
        this.passwordFieldType = "text";
      } else {
        this.passwordFieldType = "password";
      }
    },
    toggleNewPasswordVisibility() {
      if (this.newPasswordFieldType === "password") {
        this.newPasswordFieldType = "text";
      } else {
        this.newPasswordFieldType = "password";
      }
    },
    toggleConfirmPasswordVisibility() {
      if (this.confirmPasswordFieldType === "password") {
        this.confirmPasswordFieldType = "text";
      } else {
        this.confirmPasswordFieldType = "password";
      }
    },
    validation() {
      this.$refs.changePasswordForm
        .validate()
        .then((success) => success && this.onSubmit());
    },
    async onSubmit() {
      try {
        this.overlayLoading = true;
        const formData = new FormData();
        formData.append("old_password", this.passwordData.current);
        formData.append("new_password", this.passwordData.new_password);
        formData.append(
          "new_password_confirmation",
          this.passwordData.confirem_password
        );
        await this.$http.post(
          "/auth/student/edit-profile?_method=put",
          formData
        );
        this.$helpers.makeToast(
          "success",
          this.$t("success"),
          this.$t("pass_changed_successfully")
        );
        this.onCancel();
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    onCancel() {
      this.clearForm();
      this.$bvModal.hide("edit-password-modal");
    },
    clearForm() {
      this.passwordData = {
        current: null,
        new_password: null,
        confirem_password: null,
      };
    },
  },
};
</script>
